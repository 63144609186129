html {
  font-size: calc(7px + (16 - 7) * ((100vw - 320px) / (1240 - 320)));
  min-width: 320px;
}

@media (min-width: 1240px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 7px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5f4ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gluten", cursive;
  color: #333335;
}

p,
input,
button,
a,
span,
li,
th,
td {
  font-family: "Londrina Solid", cursive;
  color: #333335;
  font-weight: 300;
}

th {
  font-weight: bold;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  .main {
    width: 100%;
    flex-grow: 1;
  }
}

html,
body,
#root {
  height: 100%;
}
